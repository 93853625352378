.Input{
  background-color:#E6EEFF;
  font-size: large;
  color: rgba(0,0,0,0.5);
  width:80%;
  margin-left:7%;
  margin-right:7%;
  height:6vh;
  margin-top: 4%;
  border-radius:5px;
  outline:none;
  border: none;
  padding-right:3%;
  padding-left:3%;
}
.SearchBox::-webkit-input-placeholder {
  color: black; /*Change the placeholder color*/
  opacity: 0.5; /*Change the opacity between 0 and 1*/
}
.RadioButtonsArea{
  margin-top: 2%;
  margin-left:7%;
  margin-right:7%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.Quater{
  width: 16%;
}

.Card{
  color: black;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius:15px;
  text-align: center;
  font-size: larger;
  display: flex;
  flex-wrap: wrap;
  align-content: center;

}

.CardLast{
  color: black;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius:15px;
  text-align: center;
  font-size: larger;
  display: flex;
  flex-wrap: wrap;
  align-content: center;

}
.CardP{
  color: black;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius:15px;
  text-align: center;
  font-size: larger;
  display: flex;
  flex-wrap: wrap;
  align-content: center;

}

@media only screen and (max-width: 768px) {
  .CardsArea{
    margin-top: 14%;
    display: grid;
    margin-left:7%;
    margin-right:7%;
    grid-template-columns:100%;
    gap:5%;
    margin-bottom: 4%;
    padding-bottom: 5%;
  
  }

  .Button{
    background-color:#F55733 ;
    color: white;
    border:2px solid #E03F34;
    width:80%;
    margin-left: 10%;
    margin-right: 10%;
    min-height: 35px;
    font-size: xx-small;

   }
  .ButtonsArea{
    position: relative;
    height: 50vh;
    width: 94%;
    margin-left:3%;
    margin-right: 3%;
    display: flex;
    flex-direction:column;
    gap:10%;
    justify-content: center;
    padding: auto;
    align-items:center;
  }

  .Card{
    height:100px;
    padding-top: 8px;
    padding-bottom:8px;
  }

  .CardLast{
    height:100px;
    padding-top: 8px;
    padding-bottom:8px;
    margin-bottom:80px;
  }
  .CardP{
    height:80px;
    padding-top: 35px;
    padding-bottom:8px;
  }
  .DetailedView{
    position: relative;
    bottom: 32%;
    left: 65%;
    opacity: 50%;
    height:8px;
  }
}
@media only screen and (min-width: 768px) {
  .CardsArea{
    margin-top: 4%;
    display: grid;
    margin-left:7%;
    margin-right:7%;
    grid-template-columns:47.5% 47.5%;
    gap:5%;
    margin-bottom: 4%;
  
  }
  .Button{
    background-color:#F55733 ;
    color: white;
    border:2px solid #E03F34;
    width:18%;
    margin-left: 2%;
    margin-right: 2%;
    min-height: 35px;
   }
  .ButtonsArea{
    height: 6vh;
    width: 80%;
    display: flex;
    margin: 3%;
    margin-left:7%;
    margin-right:7%;
    flex-direction: row;
    justify-content: space-between;
  }
  .Card{
    padding-top: 20px;
    padding-bottom: 20px;
    height:140px;
  }
  .CardP{
    padding-top: 55px;
    padding-bottom: 10px;
    height:140px;
  }
  .DetailedView{
    position: relative;
    bottom: -5%;
    left: 65%;
    opacity: 50%;
    overflow-x: hidden;

  }

  
}
.CardText{
  align-content: center;
  display: flex;
  flex-direction: column;
  margin-top: 0;
  margin-bottom:0;
  width: 100%;
}






.Button:hover{
  cursor: pointer;
}

.DetailedView:hover{
  cursor: pointer;
  text-decoration: underline;  
}

.Popup{
  background-color:rgba(255, 204, 204);
  position: fixed;  
  margin-top: 3%;
  margin-left: 3%;
  padding-left:3%;
  padding-right:3%;
  padding-top:1.3%;
  padding-bottom:1%;
  border-radius: 25px;  
  text-align: left;
  color: rgb(153, 0, 0);
  width:400px;
  height:30px;
  font-size: large;
  font-weight: bold;
  display: none;
  z-index: 2;

}