.Parent{
    max-width: 757px;
    max-height: 452px;
    background-color: white;
    z-index: 3;
    margin: auto;
    display: block;
    height: 44%;
    width: auto;
    margin-top:10%;
    border-radius: 30px;
    padding: 3%;
    width: max-content;
    height: auto;
}

.Topic{
color: #000;
font-family: Jua;
font-size: 48px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.Expand{
    position: fixed; /* Stay in place */
    z-index: 3; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    float: center;
}

.OtherText{
    color: rgba(0, 0, 0, 0.70);
    font-family: Jua;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
}

.ButtonsArea{
    display: flex;
    padding-top:5% ;
    justify-content: center;
}
.BookNow{
    background: #566DE5;
    max-width: 217px;
    max-height: 78px;
    font-size: xx-large;
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 1%;
    padding-bottom: 1%;
    flex-shrink: 0;
    color: white;
    width: max-content;
    height: auto;
    margin: auto;
}
.Cancel{
    background: #EB5A5A;
    width: 217px;
    height: 78px;
    font-size: xx-large;
    flex-shrink: 0;
    color: white;
    margin: auto;
    width: max-content;
    height: auto;
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 1%;
    padding-bottom: 1%;
}
.BookNow:hover{
    cursor: pointer;
}
.Cancel:hover{
    cursor: pointer;
}