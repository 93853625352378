.Card{
    margin:4vh;
    padding: 3%;
    color: black;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius:15px;
    text-align: center;
    font-size: larger;
    align-content: center;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.Page{
  display: flex;
  flex-direction: column;
}


.Input{
    background-color:#E6EEFF;
    font-size: large;
    color: rgba(0,0,0,0.5);
    width:80%;
    margin-left:7%;
    margin-right:7%;
    height:6vh;
    margin-top: 4%;
    border-radius:5px;
    outline:none;
    border: none;
    padding-right:3%;
    padding-left:3%;
    min-height: 35px;
  }

.WrapLabelAndInput{
    display: flex;
    flex-direction: row;
}


.Lbl{
  width:65%;  
  opacity:0.5;
  font-size: medium;
  margin-left:7%;
  height:6vh;
    margin-top: 4%;
    display: flex;
    align-items: center;

}

.LblAbility{
  margin-top: 1%;
  width:80%;
  padding-left:4%;
  margin-right:7%;
  padding-right:3%;
  margin-bottom:1%;
}

.PasswordWrapper{
  position: relative;
  display:flex;
  flex-direction: row;
  background-color:#E6EEFF;
  font-size: large;
  color: rgba(0,0,0,0.5);
  width:80%;
  margin-left:7%;
  margin-right:7%;
  height:6vh;
  margin-top: 4%;
  border-radius:5px;
  outline:none;
  border: none;
  padding-right:3%;
  padding-left:3%;
  min-height: 35px; 
  align-items: center;
  
}

.Password{
  background-color:#E6EEFF;
    font-size: large;
    color: rgba(0,0,0,0.5);
    outline:none;
    border: none;
    width: 100%;
}

.CheckBoxes{
  width:80%;
  margin-left:7%;
  margin-right:7%;
  padding-right:3%;
}
  .Heading{
    font-size: xx-large;
  }

  @media only screen and (min-width: 768px) {
    .Grid{
        display: grid;
        grid-template-columns: 50% 50%;
    }

    .GridP{
      display: grid;
      margin-left:2%;
      margin-right:2%;
      grid-template-columns: 24% 24% 24% 24%;
  }

    .Parent{
      grid-template-columns: 75% 25%;
     }  
     .LblAbility{
      text-align: left;

     }
     .Topic{
      font-size: xx-large;
      font-weight: bolder;
      margin: 3%;
    }  

    .Button{
      background-color:#F55733 ;
      color: white;
      border:2px solid #E03F34;
      width:18%;
      margin-left: 2%;
      margin-right: 2%;
      min-height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
     }
     .ButtonsArea{
      display: flex;
      margin-left:7%;
      margin-right:7%;
      height:6vh;
      margin-top: 4%;
      padding-right:3%;
      padding-left:3%;
      justify-content: center;
    }
}
@media only screen and (max-width: 768px) {
    .Grid{
        display: grid;
        grid-template-columns: 100%;
    }
    .GridP{
      display: grid;
      grid-template-columns: 100%;

  }
    .Button{
        font-size: xx-small;
      
      }

    .GreenButton{
      font-size: xx-small;
    }  
      .Parent{
        grid-template-rows: 75% 25%;
       } 

      .Topic{
        font-size: xx-large;
        font-weight: bolder;
        display: flex;
        margin-top: 3%;
        margin-bottom: 3%;
        align-self: center;
        justify-self:center;
      }  
      .Button{
        background-color:#F55733 ;
        color: white;
        border:2px solid #E03F34;
        width:38%;
        margin-left: 2%;
        margin-right: 2%;
        min-height: 35px;
        max-height:35px ;
        display: flex;
        align-items: center;
        justify-content: center;
       }
       .ButtonsArea{
        display: flex;
        margin-left:7%;
        margin-right:7%;
        height:6vh;
        margin-top: 4%;
        padding-right:3%;
        padding-left:3%;
        justify-content: center;
      }
}



   .Button:hover{
    cursor: pointer;
  }



  .Parent{
    display:grid;
  }
  .CardSplitter{
    display: flex;
    flex-direction: column;
  }

  .RemoveSelected{
    color: white;
    font-weight: bolder;
    font-size: xx-large;
    display: flex;
    align-items: center;
    padding-bottom:7px;
    border-radius: 50%;
    height: 8px;
    width: 14px;
    background-color: red;
  }

  
  .RemoveSelected:hover{
    cursor: pointer;
  }
  
.GreenButton{
  background-color:#0B6623;
  color: white;
  border:2px solid #0B6623;
  width:50%;
  margin-left: 2%;
  margin-right: 2%;
  padding-left: 2%;
  padding-right: 2%;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
 }

 .GreenButton:hover{
  cursor: pointer;
}
.GreenButtonContainer{
  display: flex;
  width: 100%;
  justify-content: center;
}

.ListItem{
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 5%;
  overflow-x:auto;
  
}
.ArrowSection{
  color: red;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2%;
  padding-left: 10%;
  padding-right: 10%;
  flex-direction: row;
}
.Arrow:hover{
cursor: pointer;
}
.KeepSpace{
  height:6vh;

}

.Popup{
  background-color:rgba(255, 204, 204);
  position: fixed;  
  margin-top: 3%;
  margin-left: 3%;
  padding-left:3%;
  padding-right:3%;
  padding-top:1.3%;
  padding-bottom:1%;
  border-radius: 25px;  
  text-align: left;
  color: rgb(153, 0, 0);
  width:400px;
  height:30px;
  font-size: large;
  font-weight: bold;
  display: none;
}

.PopupTwo{
  background-color:rgb(152, 255, 152);
  position: fixed;  
  margin-top: 3%;
  margin-left: 3%;
  padding-left:3%;
  padding-right:3%;
  padding-top:1.3%;
  padding-bottom:1%;
  border-radius: 25px;  
  text-align: left;
  color: #0B4937;
  width:400px;
  height:30px;
  font-size: large;
  font-weight: bold;
  display: none;
}