.Page {
  background-color:#0040FF;
   width:100%;
   height:100%;
   min-height:100vh;
   min-width:100vw;
    display: flex;
    flex-direction: column;
}

.Heading {

   color:white;
   position: relative;
   margin-top:5%;
   top:5%;
   width:30%;
   text-align: center;
   left:35%; 
   margin-bottom:5%;

}
.Password{
  background-color:#0040FF;
  color:white;
  width: 90%;
  border: none;
  outline: none;
  font-size: large;
}


.Email{
  position: relative;
  outline:none;
  background-color:#0040FF;
  border:3px solid white;
  color:white;
  height:5%;
  font-size: large;
  padding-left:1.5%;
  padding-right:1.5%;
  padding-top: 0.4%;
  padding-bottom: 0.4%; 
  
  
}
.Button{
   position: relative;
   background-color: #00FF00;
   color: #006400; 
   height:5%;
   outline:none;
   border:3px solid #00FF00;
   font-size: large;
   box-sizing:content-box;
   padding-left:1.5%;
   padding-right:1.5%;

}



.Button:hover{
  cursor: pointer;
}


.PasswordWrapper{
  border:3px solid white;
  outline: none;
  position: relative;
  display:flex;
  flex-direction: row;
  height:5%;
  padding-left:1.2%;
  padding-right:1.2%;
  padding-top: 0.4%;
  padding-bottom: 0.4%;  
  align-items: center;
  
}

@media only screen and (min-width: 768px) {
   .Email{
    left:32.5%;
    width:32%;
    margin-top:1%;
    margin-bottom:1%;
   }
   .PasswordWrapper{
    left:32.5%;
    width:32.6%;
    margin-top:1%;
    margin-bottom:1%;
   }
   .Heading{
    font-size:5vw;
   }
   .Button{
    left:40.5%;
    width:17%;
    margin-top:3%;
    padding-top: 0.5%;
    padding-bottom: 0.5%;
   }

   .Popup{
    background-color:rgba(255, 204, 204);
    position: fixed;  
    margin-top: 3%;
    margin-left: 3%;
    padding-left:3%;
    padding-right:3%;
    padding-top:1.3%;
    padding-bottom:1%;
    border-radius: 25px;  
    text-align: left;
    color: rgb(153, 0, 0);
    width:400px;
    height:30px;
    font-size: large;
    font-weight: bold;
    display: none;
  }

 
}
@media only screen and (max-width: 768px) {
    .Email{
     left:12.5%;
     width:75%;
     margin-top:2%;
     margin-bottom:2%;
    }
    .Eye{
      margin-right: 5%;

    }
    .PasswordWrapper{
      left:12.5%;
      width:75.6%;
      margin-top:2%;
      margin-bottom:2%;
     }
    .Heading{
        margin-top:25%;
        margin-bottom:25%;   
        font-size:8vw;

    }
    .Button{
        left:12.5%;
        width:75%;
        margin-top:7%;
        padding-top: 0.5%;
        padding-bottom: 0.5%;
       }
       .Popup{
        background-color:rgba(255, 204, 204);
        position: fixed;  
        margin-top: 3%;
        margin-left: 3%;
        padding-left:3%;
        padding-right:3%;
        padding-top:1.6%;
        padding-bottom:1%;
        border-radius: 25px;  
        text-align: center;
        align-self: center;
        color: rgb(153, 0, 0);
        width:200px;
        min-height:30px;
        font-size: large;
        font-weight: bold;
        bottom:32vh;
        display: none;
        height: max-content;
      }

   
 }
 .Email::placeholder {
    color: white;
    opacity: 1; /* Firefox */
  }
  
  .Email::-ms-input-placeholder { /* Edge 12 -18 */
    color: white;
  }
  .Password::placeholder {
    color: white;
    opacity: 1; /* Firefox */
  }
  
  .Password::-ms-input-placeholder { /* Edge 12 -18 */
    color: white;
  }

  .Eye{
    color: white;
    width: 10%;
    align-self:right;
    line-height: 10;
 
     
  }
  .Eye:hover{
    cursor: pointer;
  }