.Loader {
    display: inline-block;
    width: 100%;
    height: auto;
  }
  .Loader:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    color:#0040FF ;
    border-radius: 50%;
    border: 6px solid #0040FF;
    border-color: #0040FF transparent #0040FF transparent;
    animation: Loader 1.2s linear infinite;
  }
  @keyframes Loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  