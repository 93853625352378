.Parent{
    display: flex;
  }
.Heading{
    font-weight: bolder;
    margin: 5%;

}
.ImgDiv{
    margin: 5%;
    border: 3px double black ;  
   
}

.DataRow{
    width: 100%;
    display: flex;
    flex-direction: row;
}

.NoImg{
  width: 100%;
  height: 100%;  
  align-content: center;
  font-size:7vw;
}

.RestData{
  margin-left: 5%;  
}

@media only screen and (max-width: 768px) {
    .Heading{
        font-size: 5vw;
        
    }
    .Parent{
        flex-direction: column;

    }
    .ImgDiv{
       position:relative;
        width:75%;
        height:auto;
        left:6.25%;
    }

    .DataArea{
      width: 100%;
    }
    .DataRow{
        flex-direction: column;
        width: 100%;
    }

    .SearchButton{
      background-color:#008000;
      color:white;
      width:25%;
      height: 45px;
      border: none;
      position: absolute;
      left:37.5%;
      bottom:25vh;
      
    }
  }
@media only screen and (min-width: 768px) {
.Heading{
    font-size: 3vw;
}
  
.Parent{
    flex-direction: row;

} 
.DataRow{
 justify-content: space-between;
}

.DataArea{
    display: flex;
    flex-direction: column;  
    width:50%;
    }

    .ImgDiv{
        width:25vw;
        height:25vw;
    }

    .SearchButton{
      background-color:#008000;
      color:white;
      width:100px;
      height: 45px;
      border: none;
      position: absolute;
      margin-left:100px;
      margin-top:300px
    }
  }

  
  .SearchButton:hover{
    cursor: pointer;
  }